.login-div {
  width: 40%;
  margin: auto;
  padding: 10px;
}

.login-div button {
  width: 100%;
}
.login-div p {
  text-align: center;
}
.login-div p a {
  text-decoration: none;
  color: #077dff;
}
.login-div label input:focus {
  outline-color: #077dff;
}
.check {
  width: 25%;
  display: flex;
  font-size: 14px;
}
.check .show-pass {
  width: 10%;
}
