.create-course-div {
  width: 40%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.create-course-div h2 {
  text-align: center;
}

.create-course-div form .form-group {
  margin-bottom: 15px;
}

.create-course-div form .form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.create-course-div form .form-group input,
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
textarea:focus {
  outline: none;
}

.create-course-div form .form-group button {
  background-color: #421281;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.create-course-div form .form-group button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
