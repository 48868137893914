.hero {
  min-height: 100vh;
  width: 100%;
  background-image: linear-gradient(rgba(4, 9, 30, 0.13), rgba(4, 9, 30, 0.178)),
    url("/public/background.jpg"); /* Adjust the path to match the location of your background image */

  background-position: center;
  background-size: cover;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center; /* Center vertically */
}

.text-box {
  text-align: center;
  color: #fff;
  padding: 20px;
  max-width: 600px;
}

.text-box h1 {
  font-size: 62px;
  font-weight: bold;
}
.text-box p {
  margin: 10px 0 40px;
  font-size: 16px;
  color: #fff;
}

/* course */
.heading {
  font-size: 50px;
  font-weight: bold;
}
