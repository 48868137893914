.gallery-div {
  max-width: 60%;
  margin: auto;
}

.gallery-div form {
  width: 60%;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 20px 40px;
  margin: auto;
}

.gallery-div form label {
  margin-bottom: 8px;
}

.gallery-div form input {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
}

button {
  background-color: #421281;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
input,
select {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  box-sizing: border-box;
}
h1 {
  text-align: center;
}

/* Optional: Add some spacing to the form */
