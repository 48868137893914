.form-div {
  width: 40%;
  margin: auto;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.form-div button {
  width: 100%;
}
