.event-container {
  position: relative;
  margin-bottom: 20px;
  /* border: 1px solid #ccc; */
  padding: 10px;
}

.event-div {
  position: relative;
}

.delete-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  color: red;
  cursor: pointer;
}
