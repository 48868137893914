body {
  padding-top: 0;
}
.table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.notice-table {
  width: 80%;
  margin-top: 20px;
  border-collapse: collapse;
}

.notice-table th,
.notice-table td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.notice-table th {
  background-color: #421281;
  color: white;
}

.custom-confirm-modal {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
}

.custom-confirm-modal button {
  margin: 5px;
  padding: 8px 16px;
  cursor: pointer;
}

.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 12px;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.delete {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
}
