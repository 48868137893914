.event-container {
  width: 90%;
  margin: auto;
  padding: 20px;
}
.event-container {
  position: relative;
  margin-bottom: 20px;
  padding: 10px;
}

.event-div {
  position: relative;
}

.delete-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  color: red;
  cursor: pointer;
}
.event-container .event-div {
  width: 70%;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 15px;
  border-radius: 5px;
}
.event-container .image-container img {
  width: 100%;
}
