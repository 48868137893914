.staff-list-div {
  max-width: 60%;
  margin: auto;
}

h2 {
  text-align: center;
  color: #333;
}

.staff-list-div form {
  width: 60%;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 20px 40px;
  margin: auto;
}
form input {
  border: none;
  border-bottom: 1px solid #421281;
}
input:focus {
  outline: none;
}

label {
  margin-bottom: 10px;
}

input,
select {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

button {
  background-color: #421281;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
