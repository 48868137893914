.signup-div {
  width: 40%;
  margin: auto;
}
.signup-div button {
  width: 100%;
}
.signup-div .message-box {
  font-size: 20px;
}
.signup-div .message-box a {
  color: #077dff;
  text-decoration: none;
}
.signup-div p {
  text-align: center;
}
.signup-div p a {
  text-decoration: none;
  color: #077dff;
}
