/* Gallery.css */

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  margin: 20px;
}

.image-container {
  position: relative;
  cursor: pointer;
}

img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  transition: transform 0.2s ease-in-out;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 100%;
  max-height: 80vh;
  overflow: auto;
  text-align: center;
}

.modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.modal-image {
  width: 100%;
  max-height: 80vh;
  border-radius: 8px;
}

.modal-details {
  margin-top: 15px;
}

.caption {
  font-size: 18px;
  font-weight: bold;
}

.created-time {
  color: gray;
  margin-top: 5px;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #333;
}

.close-modal:hover {
  color: #e74c3c;
}
.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #3498db; /* Button background color */
  color: #fff; /* Button text color */
  border: none;
  border-radius: 50%; /* Make it circular */
  font-size: 24px;
  padding: 15px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.floating-button:hover {
  background-color: #2980b9; /* Change background color on hover */
}

@media only screen and (max-width: 768px) {
  .gallery {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 12px;
  }

  .image-container img {
    border-radius: 6px;
  }

  .modal {
    width: 100%;
    height: 100%;
    max-height: none;
  }
}

@media only screen and (max-width: 480px) {
  .main-div {
    width: 100%;
  }
  .gallery {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 8px;
  }

  .image-container img {
    border-radius: 4px;
  }

  .modal {
    width: 90%;
    height: 100%;
    max-height: none;
  }
}
