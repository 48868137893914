.course-section {
  text-align: center;
}

.heading {
  margin-bottom: 20px;
}

.course-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.course-container .course button {
  background-color: none;
}

.course {
  margin: 10px;
  padding: 15px;
  border: 1px solid #ddd;
  position: relative;
}

.delete-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  color: red;
  cursor: pointer;
}

.create-course {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #421281;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}
