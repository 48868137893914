/* Navbar.css */

body {
  margin: 0;
  padding-top: 100px;
}

nav {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #1a02559f;
  padding: 5px;
  z-index: 1000;
  display: flex;
  align-items: center;
}

.logo-container {
  margin-right: 15px;
}

.logo {
  width: 60px;
  height: auto;
}

ul {
  list-style: none;
  padding: 0;
  display: flex;
}

nav li {
  margin-right: 15px;
  cursor: pointer;
}

nav a {
  text-decoration: none;
  color: white;
}

nav a:hover {
  color: yellow;
}

.active {
  font-weight: bold;
  color: yellow;
}

.logout {
  background-color: #f44336;
  color: white;
  padding: 5px 10px;
}
