/* StaffList.css */

.table-container {
  text-align: center;
}

.department-table {
  width: 80%;
  margin: auto;
  border-collapse: collapse;
  margin-top: 20px;
}

.department-table th,
.department-table td {
  border: 1px solid #ddd;
  padding: 10px;
}

.department-table th {
  background-color: #421281;
  color: white;
}

.delete {
  background-color: #ed0131;
  border: none;
  padding: 8px 15px;
  color: white;
  cursor: pointer;
}

.delete:hover {
  background-color: #941932;
}
/* StaffList.css */

/* ... (previous styles) */

.custom-confirm-modal {
  width: 300px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-confirm-modal h1 {
  font-size: 18px;
  margin-bottom: 10px;
}

.custom-confirm-modal p {
  font-size: 14px;
  margin-bottom: 20px;
}

.custom-confirm-modal button {
  background-color: #421281;
  color: #fff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
}
.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #3498db; /* Button background color */
  color: #fff; /* Button text color */
  border: none;
  border-radius: 50%; /* Make it circular */
  font-size: 24px;
  padding: 15px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.floating-button:hover {
  background-color: #2980b9; /* Change background color on hover */
}
