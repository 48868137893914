.department-details {
  text-align: center;
  width: 40%;
  margin: auto;
}
.staff-list {
  width: 80%;
  margin: auto;
  text-align: center;
}
